import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Image, FormGroup } from 'react-bootstrap';
import Editor from '../../simple_editor';

function ModalSharePost({ post, onSubmit, handleClose, setContent, current_user, content }) {
  const [open, setOpen] = useState(false);
  let [showPicker, setShowPicker] = useState(false);
  let [innerString, setInnerString] = useState('');

  useEffect(() => {
    if (post) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [post]);

  return (
    <React.Fragment>
      {post && (
        <Modal show={open} size="xs" centered onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Viết Bài</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            <FormGroup>
              <div className="user-share">
                <div className="p-3 d-flex">
                  <Image src={current_user?.image ? current_user?.image : '/images/avatar.png'} roundedCircle />
                  <p className="ml-2 mt-2 user-name">{current_user?.name}</p>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              {/* <div className="editor editor-post">
                <Editor
                  component="div"
                  setShowPicker={setShowPicker}
                  showPicker={showPicker}
                  setInnerString={setInnerString}
                  innerString={innerString}
                  onChange={setContent}
                  value={content}
                  createComment={onSubmit}
                  placeholder={"Bạn có suy nghĩ gì?"}
                />
              </div> */}
              <div className="editor editor-post">
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Bạn có suy nghĩ gì?"
                  style={{ width: '100%', padding: '20px', borderRadius: '5px' }}
                  onChange={e => setContent(e.target.value)}
                  defaultValue={content}
                />
              </div>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onSubmit} className="w-100 btn-share-post">
              Chia sẻ
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default ModalSharePost;
