
import _ from 'lodash';
import { routers } from '../utils/routers';
import { useContext } from 'react';
import AppContext from '../components/app_context';

export const useNotification = notification => {
  const {current_user } = useContext(AppContext);
  const manager_type = current_user?.manager_type;
  
  const moduleCommentHandle = notification => {
    let type = notification.source_type;
    let source_id = notification.source_id;

    const commentRouter = {
      Article: (article_id) =>  routers.comment.article(article_id),
      Post: (post_id) => routers.comment.post(post_id),
      Product: (product_id) =>  routers.comment.product(product_id),
      Video: (video_id) => routers.comment.video(video_id),
      Image: (image_id) =>  routers.default,
      ShortVideo: (short_video) => routers.short_video_detail(short_video)
    };
    return commentRouter[type](source_id) || routers.default;
  };

  const moduleHandleSubComment = notification => {
    const subCommentRouter = {
      default: routers.comment.sub_comment,
    };
  };

  const modulePostHandle = notification => {
    const notificationableId = notification.notificationable_id;
    const postRouter = {
      member: () => routers.post_detail(notificationableId),
      default: () => routers.manager.comment,
    };
    const handle = postRouter[manager_type] || postRouter.default;
    return handle();
  };

  const moduleInvoiceUserHandle = notification => {
    return routers.invoice.sales_order(notification.notificationable_id);
  };

  const moduleBusinessAssociationHandle = notification => {
    let notificationableId = notification.notificationable_id;
    let connectableId = notification.connectable_id;
    if (notificationableId && connectableId) {
      return routers.business_association(notificationableId, connectableId);
    }
    return routers.default;
  };

  const moduleNoticeScheduleHandle = notification => {
    const type = notification.notification_type;
    const routerNoticeSchedule = {
      weather: routers.modules.weather
    }
   return routerNoticeSchedule[type] || routers.default
  };

  const moduleUserSharePointHandle = notification => {
   return routers.default;
  };

  const moduleMarketPlaceHandle = notification => {
    let notificationableId = notification.notificationable_id;
    return routers.market_place(notificationableId);
  };

  const moduleUserHandle = notification => {
    let senderId = notification.sender_id;
    return routers.shop(senderId) || routers.default
  }

  const moduleSystemHandle = notification => {
    const sending_group = notification.sending_group;
    const systemRouter = {
      article: routers.system.article,
      post: routers.system.post,
      weather: routers.system.weather,
      npk: routers.system.npk,
      market_price: routers.system.market_price,
      technical_process: routers.system.technical_process,
      video: routers.system.video,
      traceability: routers.system.traceability,
      business_association: routers.default,
      handbook_of_pest: routers.system.handbook_of_pest,
      shop_gitf: routers.system.shop_gitf,
      short_video: routers.system.short_video,
    };
    return systemRouter[sending_group] || routers.default;
  }
  const returnHref = () => {
    let notificationType = notification.notificationable_type;
    const listNotificationable = [
      'Post',
      'UserSharePoint',
      'Shop',
      'Comment',
      'MarketPlace',
      'Image',
      'SubComment',
      'InvoiceUser',
      'Material',
      'TrainingContributionDatum',
      'CulturePlot',
      'ProcessEngineeringInformation',
      'MissionDetailUser',
      'StoreGift',
      'MembershipPackage',
      'NoticeSchedule',
      'User',
      null
    ];
    let typeCheck = _.find(listNotificationable, (e) => e === notificationType);
    switch (typeCheck) {
      case 'Post':
        return (modulePostHandle(notification));
      case 'UserSharePoint':
        return moduleUserSharePointHandle(notification);
      case 'Shop':
        return moduleUserHandle(notification);
      case 'Comment':
        return moduleCommentHandle(notification);
      case 'MarketPlace':
        return moduleMarketPlaceHandle(notification);
      case 'SubComment':
        return moduleHandleSubComment(notification);
      case 'InvoiceUser':
        return moduleInvoiceUserHandle(notification);
      case 'BusinessAssociation':
        return moduleBusinessAssociationHandle(notification);
      case 'NoticeSchedule':
        return moduleNoticeScheduleHandle(notification);
      case null:
        return moduleSystemHandle(notification);
      default:
        return routers.default;
    }
  };
  return {
    linkReturn: returnHref()
  };
};
