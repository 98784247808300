export const FETCH_INVOICE_BUSINESS = "FETCH_INVOICE_BUSINESS";
export const FETCH_INVOICE_BUSINESS_SUCCESS = "FETCH_INVOICE_BUSINESS_SUCCESS";
export const FETCH_INVOICE_BUSINESS_FAILED = "FETCH_INVOICE_BUSINESS_FAILED";

export const FETCH_INVOICE_BUSINESS_DETAIL = "FETCH_INVOICE_BUSINESS_DETAIL";
export const FETCH_INVOICE_BUSINESS_DETAIL_SUCCESS = "FETCH_INVOICE_BUSINESS_DETAIL_SUCCESS";
export const FETCH_INVOICE_BUSINESS_DETAIL_FAILED = "FETCH_INVOICE_BUSINESS_DETAIL_FAILED";

export const UPDATE_INVOICE_BUSINESS = "FETCH_INVOICE_BUSINESS";
export const UPDATE_INVOICE_BUSINESS_SUCCESS = "UPDATE_INVOICE_BUSINESS_SUCCESS";
export const UPDATE_INVOICE_BUSINESS_FAILED = "UPDATE_INVOICE_BUSINESS_FAILED";
