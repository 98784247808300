import React, { useState, useRef, useContext } from 'react';
import { DOMAIN } from 'constants';
import _ from 'lodash';
import YouTube from 'react-youtube';
import { youtubeGetID, convertString, validURL, validateStringEditor } from 'lib';
import FbImageLibrary from 'lib/react-fb-image-grid';
import MetaPost from 'components/meta_post';
import * as linkify from 'linkifyjs';
import parse, { domToReact } from 'html-react-parser';
import ShowSharePost from 'components/show_share_post';
import { toastError, toastErrorLogin } from 'helpers/toastHelper';
import ReactTimeAgo from 'react-time-ago';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import AppContext from 'components/app_context';
import Comment from './comment';
import Dropdown from 'react-bootstrap/Dropdown';
import ModalConfirmDelete from 'components/shared/modalDeleteConfirmComment/modal';
import Editor from '../../simple_editor';
import CopyUrl from '../../CopyURL/CopyUrl';
import ButtonShareFacebook from './../../ButtonShareFacebook/index';
import PintopIcon from '../../../assets/images/pin-top.png';
import FollowUser from '../FollowUser';
import CheckIcon from '../../../assets/images/check.png';

const Post = React.memo(function Post(props) {
  let {
    post,
    postFilter,
    actionLike,
    isOwner,
    actionDelete,
    addComment,
    deleteComment,
    current_user,
    actionSharePost,
    actionReportPost,
    actionSharePoint,
    actionFetchCommentPost,
    actionReportComment,
    comments,
    actionLikeComment,
    actionReplyComment,
    actionUpdateComment,
    dataUpdateComment,
    dataUpdateAnswerComment,
    isLoadMore,
    actionLoadMoreCommentsPost,
    actionSortComment,
    setPostId,
    postId,
    setPageMore,
    handleFollowPost,
    handleUnFollowPost,
    setCurrentPost,
    modifyDataDelete,
    meta,
    point,
  } = props;
  let [content, setContent] = useState('');
  const [isToggle, setIsToggle] = useState(false);
  let hei_post = 286;
  const wid_post = $('.post-box').width() - 10;
  let [report, setReport] = useState('');
  const dataUser = useSelector(state => state.login.user_data.current_user);

  let inputSharePoint = useRef(null);
  let [showTitle, setShowTitle] = useState(false);
  let [showBox, setShowBox] = useState({
    type: '',
    placeholder: '',
    active: false,
    btnName: '',
  });
  const [show, setShow] = useState(false);
  const [hover, setHover] = useState(false);
  let [contentRoot, setContentRoot] = useState('');

  const [showReportComment, setShowReportComment] = useState(false);
  const [showModalSharePoint, setShowModalSharePoint] = useState(false);
  const { isMobile, navigate } = useContext(AppContext);
  let [commentId, setCommentId] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let [reportId, setReportId] = useState(null);
  let [reportType, setReportType] = useState('');
  const handleShowReportComment = (id, type) => {
    setReportType(type);
    setReportId(id);
    setShowReportComment(true);
  };
  const handleCloseReportComment = () => setShowReportComment(false);
  const [reportComment, setReportComment] = useState('');
  const [activeSort, setActiveSort] = useState('asc');

  const [type, setType] = useState('');
  let [showPicker, setShowPicker] = useState(false);
  let [innerString, setInnerString] = useState('');
  const cmtBoxRef = useRef(null);
  const submitReportComment = (user_id, reason) => {
    if (reason === '') {
      toastError('Vui lòng nhập nội dung');
    } else {
      actionReportComment(user_id, reason, reportId, reportType);
      setShowReportComment(false);
      setReportComment('');
    }
  };

  const onReady = event => {
    event.target.pauseVideo();
  };

  const submitBox = editorRef => {
    const validation = validateStringEditor(contentRoot);
    switch (validation) {
      case 'invalid':
        return toastError('Vui lòng nhập tối thiểu 6 ký tự');
      case 'too long':
        return toastError('Vui lòng không vượt quá 1000 kí tự');
      default:
        if (showBox.type === 'comment') addComment(commentId, 'Post', contentRoot);
        else actionSharePost(post.id, 'Post', contentRoot);

        resetStateEditor(editorRef);
    }
  };

  const resetStateEditor = ref => {
    if (ref.current) {
      ref.current.innerHTML = '';
    }
    $('#editor').html('');
    setContentRoot('');
    setShowPicker(false);
    setInnerString('');
  };

  const submitReport = (id, reason) => {
    if (reason === '') {
      toastError('Vui lòng nhập nội dung báo cáo');
    } else {
      actionReportPost(id, reason);
      setShow(false);
      setReport('');
    }
  };

  const actionClick = type => {
    setIsToggle(true);
    if (current_user === null) {
      toastErrorLogin(isMobile);
    }
    setContent('');
    let isActive = type === showBox.type ? !showBox.active : true;
    if (type === 'comment') {
      setShowBox({
        type: 'comment',
        placeholder: 'Bình luận của bạn',
        active: isActive,
        btnName: (
          <div
            className="position-absolute btn-send-cmt"
            style={{
              backgroundImage: 'url("/images/send-message.png")',
            }}
          ></div>
        ),
      });
      cmtBoxRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    } else if (type === 'edit') {
      setShowBox({
        type: 'edit',
        placeholder: 'Cập nhật bình luận',
        active: isActive,
        btnName: (
          <div
            className="position-absolute btn-send-cmt"
            style={{
              backgroundImage: 'url("/images/send-message.png")',
            }}
          ></div>
        ),
      });
    } else {
      setShowBox({
        type: 'share',
        placeholder: 'Nội dung chia sẻ của bạn',
        active: isActive,
        btnName: (
          <div
            className="position-absolute btn-send-cmt"
            style={{
              backgroundImage: 'url("/images/send-message.png")',
            }}
          ></div>
        ),
      });
    }
  };

  const handleShowModal = item => {
    if (current_user) {
      setCurrentPost(item);
    } else {
      toastErrorLogin(isMobile);
    }
  };

  const handleLoadMoreComment = postId => {
    actionLoadMoreCommentsPost(postId);
    setIsToggle(true);
  };

  const acionLikePost = () => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
    } else {
      actionLike(post.classable_id, post.classable_type, post.user_liked);
    }
  };
  const LikeComment = (classable_id, classable_type, user_liked) => {
    if (current_user === null) {
      toastErrorLogin(isMobile);
    } else {
      actionLikeComment(classable_id, classable_type, user_liked);
    }
  };

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }
      if (attribs.classname === 'tag') {
        let domReact = domToReact(children, options);
        return <span onClick={evt => postFilter(domReact)}>{domReact}</span>;
      }
    },
  };

  const viewAllTitle = title => {
    setShowTitle(true);
  };

  const replace_tag = children => {
    return children.replace("<a href='https://more'>xem thêm</a>", '');
  };

  const submitSharePoint = receiver_id => {
    if (current_user.id === receiver_id) {
      toastError('Không thể tặng chính mình');
    } else {
      if (!/^\d+$/.test(inputSharePoint.current.value)) {
        toastError('Vui lòng chỉ nhập số');
      } else {
        actionSharePoint(current_user?.token_user, receiver_id, inputSharePoint.current.value);
        setShowModalSharePoint(false);
      }
    }
  };

  const ShowModalSharePoint = () => {
    if (!current_user) {
      toastErrorLogin(isMobile);
      setShowModalSharePoint(false);
    } else {
      setShowModalSharePoint(true);
    } 
  };
  const showCommentPost = (isToggle, id) => {
    setIsToggle(!isToggle);
    setPageMore(1);
    if (!isToggle) {
      setPostId(id);
      actionFetchCommentPost(post.id, 1, 5);
    }
  };

  const handleSortComment = type => {
    if (type === 'asc') {
      actionSortComment(post.id, type);
      setActiveSort(type);
      setIsToggle(true);
    } else {
      actionSortComment(post.id, type);
      setActiveSort(type);
      setIsToggle(true);
    }
  };

  let youtubeID = youtubeGetID(post.title);
  let images = _.map(
    _.sortBy(post?.images, e => e.id),
    'name',
  ).map(n => `${validURL(n) ? n : DOMAIN + n}`);
  let shop_image = validURL(post.shop_image) ? `${post.shop_image}` : `${DOMAIN}${post.shop_image}`;
  let class_like = current_user !== null && post.user_liked === true ? 'active-post' : '';
  let class_like_comment = current_user !== null && comments.user_liked === true ? 'active-post' : '';

  let shared_link_status = post.shared_link_status;
  let links =
    post.title !== null
      ? _.uniqBy(
          _.map(linkify.find(post.title, 'url'), item =>
            _.extend(
              {
                domain: item.value.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/)[1],
              },
              item,
            ),
          ),
          'domain',
        )
      : [];
  let description = post.description;
  let title =
    post.shared_post_id !== null
      ? `${description !== null ? `<div className="title">${convertString(description)}</div>` : ''}`
      : `<div className="title">
                    ${post.title !== null ? convertString(post.title) : ''}
                  </div>
                `;
  let shortTitle =
    post.short_title != '' &&
    `<div className="title">
    ${post.short_title !== null ? replace_tag(convertString(post.short_title)) : ''}
    </div> `;

  let shared_post_id = post.shared_post_id;
  let opts = {
    height: hei_post,
    width: wid_post,
    playerVars: {
      autoplay: 0,
    },
  };

  const handleHoverMove = () => {
    setHover(false);
  };

  const url = window.location.origin + '/p/' + post.id;
  const handleCloseModalShare = () => {
    setShowModalSharePoint(false);
    handleHoverMove();
  };


  const renderExpert = () => {
    return (
      <div className="member_rate_in_month d-inline-block w-100 mt-1 ">
      <span className="member_rate icon-start-arrow fs-7 me-1"></span>
      <span className="member_rate_text">
        {post?.user_role_type === 'expert' ? 'Chuyên gia' : post.member_rate}
      </span>
    </div>
    )
  }

  return (
    <div className="post-box" id={`post-id-${post?.id}`}>
      <div className="p-2 mt-1">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-7 col-2xs-12">
            <div className="wrap-flwing" style={{width: "maxContent"}}>
              <div className="user-name d-flex align-items-center">
                <Link to={`/nguoi-dung/${post?.user_slug?.toString() === 'null' ? post?.user_id : post?.user_slug}`} className="txt-limit fw-semibold fs-6">
                  {post.shop_name === null ? post.user_name : post?.shop_name}
                </Link>
                {post?.user_role_type === 'expert' && (
                  <span>
                    <img
                      src={CheckIcon}
                      alt="done"
                      style={{
                        width: '15px',
                        height: '15px',
                        marginRight: '10px',
                        marginTop: '2px',
                      }}
                    />
                  </span>
                )}
                <span className="d-inline-block">{current_user && <FollowUser post={post} isOwner={isOwner} />}</span>
              </div>
              <Link to={`/nguoi-dung/${post?.user_slug?.toString() === 'null' ? post?.user_id : post?.user_slug}`} className="user-inf d-flex">
                <div className="d-flex mt-1">
                  <img src={post.shop_image ? shop_image : '/images/avatar.png'} alt="comment images" />
                  <div className="txt-sectn">
                    <p className="post-time">
                      <span className="icon-location01" /> <span>{post?.province_name ?? 'Không xác định'}</span>
                    </p>
                    <p className="post-time">
                      <span className="icon-time align-middle"></span>{' '}
                      <ReactTimeAgo date={Date.parse(post.updated_at)} />
                    </p>
                    {isMobile && renderExpert()}
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-5 col-2xs-12 d-flex text-end position-relative">
              {!isMobile &&  renderExpert()}
            <div className="pin_droplist d-flex">
              {post.pin_top === 1 && (
                <div className=' mt-1 ml-1 pin-icon'>
                  <img src={PintopIcon} alt="icon-pin"/>
                </div>
              )}
              <div className="dropdown post-report">
                <>
                  <Dropdown>
                    <Dropdown.Toggle as="button" className="btn dropdown-toggle">
                      <span className="icon-menu-dot" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu as="ul" className="dropdown-menu dropdown-menu-end">
                      {!isOwner && current_user ? (
                        !post.user_followed ? (
                          <li
                            onClick={() => handleFollowPost(post.classable_type, post.classable_id)}
                            className="dropdown-item"
                          >
                            <a role="button">Theo dõi bài viết</a>
                          </li>
                        ) : (
                          <li
                            onClick={() => handleUnFollowPost(post.classable_type, post.classable_id)}
                            className="dropdown-item"
                          >
                            <a role="button">Bỏ theo dõi</a>
                          </li>
                        )
                      ) : (
                        ''
                      )}
                      <Link to={`/p/${post.id}`} className="dropdown-item">
                        <li>
                          <span> Xem chi tiết</span>
                        </li>
                      </Link>

                      {isOwner || current_user?.manager_type == 'admin' ? (
                        <li className="dropdown-item">
                          <a role="button" onClick={() => modifyDataDelete(post.id)}>
                            Xóa bài viết
                          </a>
                        </li>
                      ) : (
                        ''
                      )}
                      {meta?.deleteData != null && (
                        <ModalConfirmDelete
                          onDelete={() => actionDelete(post.id)}
                          isOpen={meta.deleteData}
                          onToggle={() => modifyDataDelete(null)}
                        />
                      )}

                      {!isOwner && current_user && (
                        <li className="dropdown-item">
                          <a href="#" onClick={handleShow}>
                            Báo vi phạm
                          </a>
                        </li>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Báo vi phạm</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <input
                        className="form-control"
                        name="message"
                        placeholder="Nhập lý do"
                        value={report}
                        onChange={e => setReport(e.target.value)}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <button type="button" className="btn btn-primary" onClick={() => submitReport(post.id, report)}>
                        {' '}
                        gửi
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
              </div>
            </div>  
          </div>
        </div>
      </div>
      <div className="posted-inf">
        {post.short_title == '' && <div className="cont">{parse(title, options)}</div>}
        {post.short_title != '' && (
          <div className="cont">
            {showTitle
              ? parse(title, options) //parse(post?.title.replace(/\n/g, "<br />"), options)
              : parse(shortTitle, options)}
            {showTitle ? (
              ''
            ) : (
              <a role="button" className="btn-more" onClick={() => viewAllTitle(post?.title)}>
                xem thêm
              </a>
            )}{' '}
          </div>
        )}
        <div className="media" style={{ display: 'block' }}>
          {youtubeID != null && (
            <YouTube containerClassName={'video-youtube'} videoId={youtubeID} opts={opts} onReady={onReady} />
          )}
          {!shared_post_id && (
            <div key={post.id} className="post_images">
              <FbImageLibrary
                post={post}
                listImage={_.sortBy(post?.images, o => o.id)}
                navigate={navigate}
                images={images}
                countFrom={3}
                renderOverlay={() => <div className="view-image">Xem full hình</div>}
                overlayBackgroundColor={'#0005'}
              />
            </div>
          )}
          {youtubeID === null && links.length > 0 && (
            <MetaPost links={links} shared_link_status={shared_link_status} postId={post.id} />
          )}
          {shared_post_id && (
            <ShowSharePost
              listImage={post?.images}
              navigate={navigate}
              post={post}
              shared_post_id={shared_post_id}
              options={options}
              opts={opts}
              onReady={onReady}
            />
          )}
        </div>
        <div className="fav-inf d-flex justify-content-between align-items-center">
          <div className="like me-auto">
            <span className="icon-like01" />
            <span className="txt ml-1">{post.total_like}</span>
            <div className="ml-3">
              <span className="icon-eye-line01"></span> <span className=" txt">{post.viewed * 10 || 0}</span>
            </div>
          </div>
          <div
            className="cmnt"
            onClick={() => {
              setCommentId(post.id);
              showCommentPost(isToggle, post.id);
              actionClick('comment');
              setType('comment');
            }}
          >
            <span className="numb">{post.total_comment}</span>
            <span className="txt"> Bình luận</span>
          </div>
        </div>
        <div className=" d-flex fav-ctrl justify-content-around align-items-center">
          <div
            onClick={() => acionLikePost(post.classable_id, post.classable_type, post.user_liked)}
            className="like d-flex align-items-center"
          >
            <span className={`${class_like} icon-like01`} />
            <span className={`${class_like} txt fw-semibold hover ml-1`}>Thích</span>
          </div>
          {!isOwner && (
            <div className="cmnt d-flex align-items-center" onClick={ShowModalSharePoint}>
              <span className="icon-get-gift" />
              <span className="txt fw-semibold hover ml-1 ">Tặng điểm</span>
            </div>
          )}

          <Modal
            show={showModalSharePoint}
            onHide={() => handleCloseModalShare()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-between mt-2">
                <div className="d-flex align-items-center">
                  <img src={current_user?.image ?? '/images/avatar.png'} alt=" images" className="avtar-sharepoint" />
                  <div>
                    <div className="txt-limit">{current_user?.name ?? current_user?.shop?.name}</div>
                    <div className="mt-2">
                      <img src="../images/rice (1).png" style={{ width: '20px', height: '20px' }} />
                      <span className="ml-1">{point}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <img src="../images/gift (1).png" style={{ width: '30px', height: '30px' }} />
                </div>
              </div>
              <div className="position-relative my-3">
                <input
                  className="form-control"
                  name="message"
                  placeholder={`Nhập điểm muốn tặng ${post?.user_name} `}
                  onKeyPress={event => {
                    if (!/^[0-9]*$/.test(event.key)) {
                      event.preventDefault();
                      toastError('Vui lòng chỉ nhập số');
                    }
                  }}
                  ref={inputSharePoint}
                />
                <button
                  type="button"
                  className="btn position-absolute  btn-sm btn-primary"
                  onClick={() => submitSharePoint(post?.user_id)}
                  style={{ top: '3px', right: '5px' }}
                >
                  {' '}
                  Tặng
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <div
            className="cmnt d-flex align-items-center"
            onClick={() => {
              actionClick('comment');
              setCommentId(post.id);
              setType('comment');
              showCommentPost(isToggle, post.id);
            }}
          >
            <span className="icon-comment-line" />
            <span className="txt fw-semibold ml-1  hovercolor-585858">Bình luận</span>
          </div>
          <div className="share d-flex align-items-center">
            <div className="dropdown post-report">
              <button
                className="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  backgroundColor: 'transparent',
                  color: '#',
                  height: '100%',
                }}
              >
                <span className="icon-share01" style={{ content: '' }}></span>
                <span className="txt fw-semibold ml-1 hover">Chia sẻ</span>
              </button>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="true" style={{ width: '12rem' }}>
                <li className="dropdown-item">
                  <div style={{ height: '32', width: '32', cursor: 'pointer' }} className="mr-2">
                    <ButtonShareFacebook>
                      <span className="ml-1">Chia sẻ Facebook</span>
                    </ButtonShareFacebook>
                  </div>
                </li>
                <li className="dropdown-item">
                  <div
                    style={{ height: '32', width: '32', cursor: 'pointer' }}
                    className="mr-2"
                    onClick={() => handleShowModal(post)}
                  >
                    <img
                      src="/images/logo_2nong.png"
                      alt="2Nong-share"
                      style={{
                        height: '32',
                        width: '32',
                        cursor: 'pointer',
                        backgroundColor: '#009858',
                        borderRadius: '3px',
                      }}
                    />
                    <span className="hover ml-1">Chia sẻ diễn đàn</span>
                  </div>
                </li>
                <li className="dropdown-item">
                  <div style={{ height: '32', width: '32', cursor: 'pointer' }} className="mr-2">
                    <CopyUrl url={url} />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div ref={cmtBoxRef}>
          {showBox.active && current_user !== null && (
            <div className="cmt-box">
              <div className="inpt-cntn cmnt-box position-relative">
                <div className="editor editor-post">
                  <Editor
                    component="div"
                    setShowPicker={setShowPicker}
                    showPicker={showPicker}
                    setInnerString={setInnerString}
                    innerString={innerString}
                    onChange={setContentRoot}
                    value={contentRoot}
                    createComment={submitBox}
                    placeholder={showBox.placeholder}
                  />
                </div>
              </div>
              <Comment
                isToggle={isToggle}
                post={post}
                postId={postId}
                comments={comments}
                handleSortComment={handleSortComment}
                activeSort={activeSort}
                current_user={dataUser}
                deleteComment={deleteComment}
                options={options}
                class_like_comment={class_like_comment}
                showReportComment={showReportComment}
                LikeComment={LikeComment}
                setContent={setContent}
                handleCloseReportComment={handleCloseReportComment}
                reportComment={reportComment}
                handleShowReportComment={handleShowReportComment}
                content={content}
                submitReportComment={submitReportComment}
                setReportComment={setReportComment}
                dataUpdateComment={dataUpdateComment}
                actionUpdateComment={actionUpdateComment}
                isLoadMore={isLoadMore}
                handleLoadMoreComment={handleLoadMoreComment}
                actionReplyComment={actionReplyComment}
                actionClick={actionClick}
                dataUpdateAnswerComment={dataUpdateAnswerComment}
                actionReportComment={actionReportComment}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Post;
