import React, { useEffect, useState } from 'react';
import {invoiceDetailPublic} from 'apis/invoice';
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import { useParams } from "react-router-dom";
import useInvoice from "../../context/Inovice/useInvoice";
import OrderInformation from "./Components/OrderInformation";
import InvoiceItems from "./Components/InvoiceItems";

function DetailInvoiceNotAuthen() {
  const { invoiceId } = useParams();
  const [invoiceDetail, setInvoiceDetail] = useState(null);
  const [error, setError] = useState(false);
  const {invoiceStatus} = useInvoice();

  async function getInvoicePublic(){
    const response = await invoiceDetailPublic(invoiceId);
    return response;
  }

  useEffect(() => {
    getInvoicePublic().then((response) => {
      setInvoiceDetail(response.data.data);
      setError(false);
    }).catch((err) => {
      setError(true)
    });
  }, [invoiceId]);

  return (
    <>
      <AddMetaTags title={`Thông tin đơn hàng - ${invoiceId}`} />
      <div className="detail-invoice">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            {error ? <p className='fw-bold '>Không tìm thấy đơn hàng !</p> :
              <>
                <OrderInformation data={invoiceDetail} invoiceStatus={invoiceStatus} showReturnInfo={false} />
                <InvoiceItems  sellerName={invoiceDetail.seller_name} invoiceItems={invoiceDetail.invoice_items} totalPrice={invoiceDetail.price_total} />
              </>}
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailInvoiceNotAuthen;
