import React, { useState, useEffect } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { toastError, toastSuccess } from 'helpers/toastHelper';
import ImageUploading from 'react-images-uploading';
import AddImageIcon from '/assets/images/add-image.png';
import AddVideoIcon from '/assets/images/add-video.png';
import { fetchDetailRefundInvoice, createRefundInvoice, updateStatusRefundInvoice } from 'actions/refund_invoice';
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./refundModal.scss";

const RefundModal = ({ invoiceUserId, openModal, setOpenModal, type }) => {
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [remark, setRemark] = useState('');
  const [enoughImage, setEnoughImage] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = () => setOpenModal(false);

  const handleBuyerReturn = async () => {
    const formData = new FormData();

    if (!remark) {
      return toastError("Vui lòng nhập lý do");
    }
    formData.append('remark', remark);

    if (images.length > 0) {
      images.forEach((image) => {
        if (image && image.file) {
          formData.append('attachment[file][]', image.file); // Thêm tệp vào formData
        }
      });
    } else {
      return toastError("Vui lòng chọn ít nhất một ảnh");
    }

    if (video && video.file) {
      formData.append('attachment[file][]', video.file);
    }

    const isBuyer = type === "buyer";
    const confirmationText = isBuyer ? 'Xác nhận gửi yêu cầu trả hàng' : 'Xác nhận từ chối yêu cầu trả hàng';
    const successMessage = isBuyer ? "Yêu cầu trả hàng đã được gửi" : "Đã từ chối yêu cầu trả hàng";

    Swal.fire({
      title: confirmationText,
      text: isBuyer ? "Trả hàng" : "Từ chối",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#009a59',
      cancelButtonColor: '#d33',
      cancelButtonText: "Đóng",
      confirmButtonText: 'Xác nhận',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (isBuyer) {
          formData.append('invoice_user_id', invoiceUserId);
          await dispatch(createRefundInvoice(formData));
        } else {
          formData.append('invoiceId', invoiceUserId);
          formData.append('status', 'rejected');
          await dispatch(updateStatusRefundInvoice(formData));
        }
        navigate(isBuyer ? "/nguoi-dung/don-hang-cua-toi" : `/nguoi-dung/thong-tin-don-ban-hoan-tra/${invoiceUserId}`);
        if (!isBuyer) {
          setTimeout(() => {
            dispatch(fetchDetailRefundInvoice({type: 'sales', invoiceId: invoiceUserId}));
          }, 1000);
        }
        setOpenModal(false);
      }
    });
  };

  const handleChangeImages = (imageList) => {
    if (imageList.length > 5) {
      toastError('Không vượt quá 5 ảnh');
      return;
    }
    setImages(imageList);
    setEnoughImage(imageList.length === 5);
  };

  const handleChangeVideo = (event) => {
    const files = event.target.files;
    const videoFile = Array.from(files).find(file => file.type.startsWith('video/'));
    if (videoFile) {
      if (video) {
        return toastError('Chỉ được phép tải lên 1 video');
      }
      setVideo({ file: videoFile });
    }
  };

  useEffect(() => {
    if (openModal) {
      document.getElementById('modal-title').focus();
    }
  }, [openModal]);

  return (
    <Modal show={openModal} onHide={closeModal} aria-labelledby="modal-title" centered>
      <Modal.Header closeButton>
        <Modal.Title id="modal-title">Lý do huỷ đơn</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label htmlFor="remark">Lý do</label>
          <textarea
            id="remark"
            rows={3}
            placeholder="Nhập lý do..."
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <Row className="g-2 mt-2">
          <p className="mt-2">Ảnh đơn hàng ({ images.length || 0 }/5):</p>
          <ImageUploading
            multiple
            maxNumber={5}
            value={images}
            onChange={handleChangeImages}
          >
            {({ imageList, onImageRemove, onImageUpload }) => (
              imageList.concat(null).map((image, index) =>
                image ? (
                  <Col key={index} xs={2}>
                    <div className="border border-secondary border-opacity-50 ImageAttachment shadow-sm">
                      <button className="btn-close" onClick={() => onImageRemove(index)} aria-label="Remove image" />
                      <img src={image.dataURL} alt="" className="modal-show-media h-75" />
                    </div>
                  </Col>
                ) : (
                  !enoughImage && (
                    <Col xs={2} key={'empty-image'}>
                      <div className="d-flex w-100 h-100 bg-white" onClick={onImageUpload}>
                        <div className="ImageAttachment">
                          <img src={AddImageIcon} className="buttonAddImage" alt="Thêm ảnh" />
                        </div>
                      </div>
                    </Col>
                  )
                )
              )
            )}
          </ImageUploading>
        </Row>

        <p className="mt-2">Video đơn hàng ({video ? 1 : 0}/1):</p>
        {!video && (
          <div>
            <Col xs={2} key={'empty-video'}>
              <label htmlFor="video-upload" className="video-upload-label d-flex w-100 h-100 bg-white">
                <img
                  src={AddVideoIcon}
                  alt="Upload Video"
                  className="video-upload-image"
                />
                <input
                  id="video-upload"
                  type="file"
                  accept="video/*"
                  onChange={handleChangeVideo}
                  className="form-control"
                  style={{ display: 'none' }} // Ẩn input file
                />
              </label>
            </Col>
          </div>
        )}

        {video && (
          <Row className="g-2 mt-2">
            <Col xs={12}>
              <div className="mt-2">
                <video className="modal-show-media h-75 model-show-video" controls>
                  <source src={URL.createObjectURL(video.file)} type={video.file.type} />
                  Trình duyệt của bạn không hỗ trợ video.
                </video>
              </div>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-warning btn-sm" variant="secondary" onClick={closeModal}>
          Đóng
        </Button>
        <Button className="btn btn-success btn-sm" variant="primary" onClick={handleBuyerReturn}>
          { type === "buyer" ? 'Trả hàng' : 'Từ chối' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RefundModal;
