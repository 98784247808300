import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
import { get_user } from 'lib';

export const connectShipping = params => {
  return axiosService.post(`${DOMAIN}/api/v2/logistics/connect`, params);
};

export const getListShipping = shop_id => {
  let current_user = get_user();
  axiosService.setHeader('Authorization', `Bearer ${current_user.token2_user}`);
  return axiosService.get(`${DOMAIN}/api/v2/logistics/get_list_shipping_provider?shop_id=${shop_id}`, true);
};

export const updateConnectionStatus = params => {
  axiosService.setHeader('Access-Control-Allow-Origin', '*');
  axiosService.setHeader('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH');
  return axiosService.put(`${DOMAIN}/api/v2/logistics/update_connection_status`, params);
};

export const getShippingFee = params => {
  axiosService.setHeader('Access-Control-Allow-Origin', '*');
  axiosService.setHeader('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,PATCH');
  return axiosService.post(`${DOMAIN}/api/v2/logistics/get_shipping_fee`, params);
};

