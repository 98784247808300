import axiosService from 'services/axiosService';
import { DOMAIN } from 'constants';
import qs from 'query-string';

const business_url = 'api/v2/business';

export const getListBusinessAssociation = (params = { page: 1, limit: 20 }) => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${business_url}/business_associations${query}`, true);
};

export const getBusinessDetail = businessId => {
  return axiosService.get(`${DOMAIN}/${business_url}/business_associations/${businessId}`, true);
};

export const getBusinessProduct = (params = { page: 1, limit: 20 }) => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${business_url}/products${query}`, true);
};

export const deleteProductBusiness = params => {
  const { businessId, productId } = params;
  return axiosService.delete(`${DOMAIN}/${business_url}/products/${productId}?business_association_id=${businessId}`);
};

export const createBusinessProduct = params => {
  return axiosService.post(`${DOMAIN}/${business_url}/products`, params);
};

export const importBusinessProductApi = params => {
  return axiosService.post(`${DOMAIN}/${business_url}/products/import_product`, params);
};

export const getDetailBusinessProduct = params => {
  const { productId, businessId } = params;
  return axiosService.get(
    `${DOMAIN}/${business_url}/products/${productId}?business_association_id=${businessId}`,
    true,
  );
};

export const updateBusinessProduct = params => {
  const { productId, formData } = params;
  return axiosService.put(`${DOMAIN}/${business_url}/products/${productId}`, formData);
};

export const getBusinessInvoice = params => {
  const { businessId } = params;
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${business_url}/business_associations/${businessId}/invoices${query}`, true);
};

export const getBusinessInvoiceDetail = params => {
  const { invoiceId, businessId } = params;
  return axiosService.get(`${DOMAIN}/${business_url}/business_associations/${businessId}/invoices/${invoiceId}`, true);
};

export const updateBusinessInvoice = params => {
  const { invoiceId, businessId } = params;
  return axiosService.put(
    `${DOMAIN}/${business_url}/business_associations/${businessId}/invoices/${invoiceId}`,
    params,
  );
};

export const getListBusinessEmployee = params => {
  const { businessId } = params;
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${business_url}/business_associations/${businessId}/employees${query}`, true);
};

export const updateBusinessEmployee = params => {
  const { employeeId, businessId, payload } = params;
  return axiosService.put(
    `${DOMAIN}/${business_url}/business_associations/${businessId}/employees/${employeeId}`,
    payload,
  );
};

export const createBusinessEmployee = params => {
  const { businessId, payload } = params;
  return axiosService.post(`${DOMAIN}/${business_url}/business_associations/${businessId}/employees`, payload);
};

export const deleteBusinessEmployee = params => {
  const { businessId, employeeId } = params;
  return axiosService.delete(`${DOMAIN}/${business_url}/business_associations/${businessId}/employees/${employeeId}`);
};
export const getEnterPrises = params => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${business_url}/business_associations/enterprise/all${query}`);
};

export const getProductOfEnterprise = params => {
  let query = '';
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${business_url}/products/business_association/all${query}`);
};
