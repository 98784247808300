import React, { useEffect, useState }from "react";
import * as Yup from "yup";
import NumberFormat from "components/number_format";
import ServiceModal from "./serviceModal";
import { getShippingFee } from "../../../apis/shipping";
import { toastError, toastSuccess } from 'helpers/toastHelper';
import { useFormik } from "formik";
import { createOrder } from "../../../apis/order";
import { useNavigate } from "react-router-dom";


const FormGHTK = ({
    invoiceDetail, 
    invoiceId,
    shippingUnitId,
    pickOption,
  }) => {

  const [weight, setWeight] = useState(0);
  const [shipmentType, setShipmentType] = useState('exp');
  const [transport, setTransport] = useState('road');
  const [hiddenElement, setHiddenElement] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [tags, setTags] = useState([]);
  const [fee, setFee] = useState(0);
  const [agree, setAgree] = useState(true);
  let navigate = useNavigate();

  const handleChangeShippingType = (e) => {
    setShipmentType(e.target.value)
    if (e.target.value === "bbs") {
      setHiddenElement(true)
      setShipmentType(e.target.value)
      setTransport('')
      if (weight <= 0) {
        toastError('Vui lòng nhập khối lượng lớn hơn 0');
        return;
      }
      createShippingFee(invoiceId, shippingUnitId, e.target.value, '' , weight, tags)
    }else {
      setHiddenElement(false)
      setShipmentType(e.target.value)
      setTransport('road')
      if (weight <= 0) {
        toastError('Vui lòng nhập khối lượng lớn hơn 0');
        return;
      }
      createShippingFee(invoiceId, shippingUnitId, e.target.value, 'road', weight, tags)
    }
  }

  const handleSubmmitShowFee = () => {
    setOpenModal(false)
  }

  const handleChangeTranport = (e) => {
    setTransport(e.target.value)
    if (weight <= 0) {
      toastError('Vui lòng nhập khối lượng lớn hơn 0');
      return;
    }
    createShippingFee(invoiceId, shippingUnitId, shipmentType, e.target.value, weight, tags)
  }

  const handleClick = () => {
    setOpenModal(true)
  };

  const handleInputCalculFee = async (e) => {
    if (weight <= 0) {
      toastError('Vui lòng nhập khối lượng lớn hơn 0');
      return;
    }
    if(weight >= 20000) {
      setShipmentType('bbs')
      setTransport('')
      createShippingFee(invoiceId, shippingUnitId, 'bbs', '', weight, tags)
    } else {
      setShipmentType('exp')
      setTransport('road')
      createShippingFee(invoiceId, shippingUnitId, 'exp', 'road', weight, tags)
    }
  }

  const createShippingFee = async (invoiceId, shippingUnitId, shipmentType, transport, weight, tags) => {
    const params = {
      invoice_id: invoiceId, // Mã đơn hàng
      shipping_id: shippingUnitId, // ĐVVC
      shipment_info: {
          shipment_type: shipmentType, // Nhận 2 giá trị: exp hoặc bbs. exp là Express < 20KG, bbs là >=20KG
          transport: transport, // road: đường bộ, fly: đường bay (mặc định không truyền là 'fly'
          pick_option: pickOption // cod: lấy hàng tại nhà, post: lấy hàng tại bưu cục
      },    
      package_info: {
          weight: weight, // Cân nặng gói hàng
          tags: tags // ID tag lấy tại https://docs.giaohangtietkiem.vn/?http#ng-n-h-ng. Kéo xuống search "Mô tả giá trị nhãn đơn hàng tags"
      }
    }
    const { data } = await getShippingFee(params)
    setFee( shipmentType === 'bbs' ? data.data.value : data.data.fee)
  }

  useEffect(() => {
    if (weight > 0) {
      createShippingFee(invoiceId, shippingUnitId, shipmentType, transport, weight, tags)
    }
  }, [tags])


  const {
    handleSubmit,
  } = useFormik({
    initialValues: {
      invoice_id: invoiceId,
      shipping_id: shippingUnitId,
      shipment_info: {
          shipment_type: shipmentType,
          transport: transport,
          pick_option: pickOption 
      },    
      package_info: {
          weight: weight,
          tags: tags
      }
    },
    onSubmit: async (values, { resetForm }) => {
      const params = {
        invoice_id: invoiceId,
        shipping_id: shippingUnitId,
        shipment_info: {
            shipment_type: shipmentType,
            transport: transport,
            pick_option: pickOption 
        },    
        package_info: {
            weight: weight,
            tags: tags
        }
      };
      try {
        const response = await createOrder(params);
        if (response.data.success === true) {
          toastSuccess(response.data)
          resetForm();
          navigate("/nguoi-dung/don-hang-cua-toi")
        } else 
        {
          toastError(response.data);
        }
      } catch (error) {
        toastError(error.data.data);
      }
    },
  });


  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3>Thông tin giao hàng <span className="red-clr">*</span></h3>
        <div className="text-center">
          <button className="btn btn-success fw-bold"  
                  type="button"
                  style={{ padding: "0.3rem 0.5rem" }} 
                  onClick={(e) => handleSubmit(e)} 
                  disabled={agree}>
                  Tạo vận đơn
          </button>
        </div>
      </div>
      <div className="d-flex mt-4">
        <div className="text-black w-50">
          <div>
            <div className="d-flex justify-content-between border-bottom w-50">
              <div className="w-50">Khối lượng <span className="red-clr">*</span></div>
              <div className="w-50">
                <input
                  className="border-0 w-75"
                  placeholder="0.5"
                  id="weight"
                  type="number"
                  name="weight"
                  value={weight}
                  onBlur={(e) => handleInputCalculFee(e)}
                  onChange={(e) => setWeight(parseFloat(e.target.value))}
                />
                <span>g</span>
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex border-bottom w-50  justify-content-between">
            <div>
              <div>Phí ship</div>
            </div>
            <div className="w-50 text-end text-black-50">{fee} đ</div>
          </div>
          <div className="mt-4 d-flex border-bottom w-50 justify-content-between">
            <div>
              <div>Thu hộ</div>
            </div>
            <div className="w-50 text-end text-black-50"><NumberFormat>{invoiceDetail?.price_total}</NumberFormat> đ</div>
          </div>
        </div>
        <div className="w-50">
          <div className="d-flex justify-content-between">
            <div className="mr-1">
              <div>
                <label className="radio-button-cus m-0">
                  <input
                    className="mr-2 radio-button-custom"
                    type="radio"
                    id={`shipping-type-exp`}
                    name="shippingType"
                    value="exp"
                    onChange={(e) => handleChangeShippingType(e)}
                    checked={shipmentType === 'exp'}
                  />
                  <span htmlFor={`shipping-type-exp`} className={`label-radio-button ${shipmentType === 'exp' ? 'selected' : ''}`}></span>
                  Express nhanh &lt; 20kg
                </label>
              </div>
              {!hiddenElement && (
                <div className="mt-2 ml-2 ">
                  <div>
                    <label className="radio-button-cus m-0">
                      <input
                        className="mr-2 radio-button-custom"
                        type="radio"
                        id={`shipping-road`}
                        name="shippingTranport"
                        value="road"
                        onChange={(e) => handleChangeTranport(e)}
                        checked={transport === 'road'}
                      />
                      <span htmlFor={`shipping-road`} className={`label-radio-button ${shipmentType === 'exp' ? 'selected' : ''}`}></span>
                      Đường bộ
                    </label>
                  </div>
                  <div>
                    <label className="radio-button-cus m-0">
                      <input
                        className="mr-2 radio-button-custom"
                        type="radio"
                        id={`shipping-plane`}
                        name="shippingTranport"
                        value="fly"
                        onChange={(e) => handleChangeTranport(e)}
                        checked={transport === 'fly'}
                      />
                      <span htmlFor={`shipping-plane`} className={`label-radio-button ${shipmentType === 'exp' ? 'selected' : ''}`}></span>
                      Đường bay
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div>
            <label className={`radio-button-cus m-0`}>
                <input
                  className="mr-2 radio-button-custom"
                  type="radio"
                  id={`shipping-type-bbs`}
                  value="bbs"
                  name="shippingType"
                  onChange={(e) => handleChangeShippingType(e)}
                  checked={shipmentType === 'bbs'}
                />
                <span htmlFor={`shipping-type-bbs`} className={`label-radio-button ${shipmentType === 'bbs' ? 'selected' : ''}`}></span>
                BBS &gt;= 20kg
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {/* <div className="w-50">
          <div className="w-75 border-bottom d-flex justify-content-between">
            <div>
              Ảnh đơn hàng
            </div>
            <div className="upload-avatar">
              <label htmlFor="avatar-upload" className="icon-camera-label cursor-pointer">
                <span className="icon-camera" />
              </label>
              <input
                id="avatar-upload"
                type="file"
                className="form-control"
                onChange={uploadImage}
                style={{ display: 'none' }}
                defaultValue=""
              />
            </div>
          </div>
          {urlImage && (
            <div>
                <img src={urlImage} alt="imagePreview" className="imagePreview" style={{ width: '150px', marginTop: '10px' }} />
              </div>
            )}
        </div> */}
        <div className="w-50">
          <div className="w-100 border-bottom d-flex justify-content-between">
            <div>
              Dịch vụ thêm
            </div>
            <div className="font-14px text-black-50 cursor-pointer fst-italic text-decoration-underline" onClick={() => handleClick()}>
              tùy chọn
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 form-checkbox font-14px d-flex position-relative">
        <input
          type="checkbox"
          name="checkbox_dieu_khoan"
          className="mr-1 form-check-input"
          onChange={(e) => setAgree(!e.target.checked)}
        />
        <div>
          Tôi đã đọc hiểu và đồng ý với <a className="text-chinh-sach" href="https://cache.giaohangtietkiem.vn/d/7d59b37e9566d6ba55529cc5017ea371.pdf" target="_blank"> Điều khoản và quy định </a> và <a className="text-chinh-sach" href="https://cache.giaohangtietkiem.vn/d/1820ea6760b34ad5971e4efe4902d979.pdf" target="_blank"> Chính sách bảo mật</a>
        </div>
      </div>
      <ServiceModal 
        listOptions={[]}
        mainService={''}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setTags={setTags}
        weight={weight}
        handleSubmmitShowFee={handleSubmmitShowFee}
        titleModal="Chính sách tuỳ chọn"
        typeModal={'ghtk'}
      />
    </div>
  );
};

export default FormGHTK;
