import qs from "query-string";
import axiosService from "services/axiosService";
import { DOMAIN } from "constants";

const url_invoice = "api/v2/invoice_users";

export const listInvoiceUser = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_invoice}${query}`, true); // Danh sách đơn hàng đã order dành cho người mua
};

export const createInvoice = (params) => {
  return axiosService.post(`${DOMAIN}/${url_invoice}`, params); // Người mua tạo đơn hàng chi tiết
};

export const buyerCancelInvoice = (params) => {
  const invoice_user_id = params.invoiceUserId;
  const payload = {
    status: params.status,
    remark: params.remark,
  };
  return axiosService.put(`${DOMAIN}/${url_invoice}/${invoice_user_id}`, payload); // Người mua hủy đơn hàng
};

export const sellerUpdateStatusInvoice = (params) => {
  const invoice_user_id = params.invoiceUserId;
  const payload = {
    status: params.status,
    remark: params.remark,
  };
  return axiosService.put(`${DOMAIN}/${url_invoice}/${invoice_user_id}`, payload);
}; // người bán và mua cập nhật trạng thái đơn hàng


export const sellerUpdateStatusInvoiceOld = (params) => {
  const invoice_user_id = params.invoiceUserId;
  const payload = {
    status: params.status,
  };
  return axiosService.put(`${DOMAIN}/${url_invoice}/shop/${invoice_user_id}`, payload);
}; // người bán và mua cập nhật trạng thái đơn hàng đây là api cũ

export const buyerViewInvoice = (invoiceId) => {
  return axiosService.get(`${DOMAIN}/${url_invoice}/${invoiceId}`, true);
};

export const sellerViewInvoice = (invoiceId) => {
  return axiosService.get(`${DOMAIN}/${url_invoice}/${invoiceId}`, true); //api mới
}

export const listInvoiceSeller = (params) => {
  let query = "";
  if (Object.keys(params).length > 0) {
    query = `?${qs.stringify(params)}`;
  }
  return axiosService.get(`${DOMAIN}/${url_invoice}${query}`, true); //Danh sách đơn hàng dành cho người bán
};

export const invoiceDetailPublic = (invoiceId) => {
  return axiosService.get(`${DOMAIN}/${url_invoice}/public_show/${invoiceId}`);
}
