import React, { useEffect, useRef, useState } from "react";
import LeftSidebar from "components/left_sidebar";
import AddMetaTags from "components/add_meta_tags";
import { useNavigate, useParams } from "react-router-dom";
import {fetchDetailInvoice, updateStatusInvoice, updateStatusInvoiceOld} from "actions/invoice";
import { useDispatch, useSelector } from "react-redux";
import useInvoice from "../../context/Inovice/useInvoice";
import DetectAction from "./Components/DetectActionByStatus";
import Swal from "sweetalert2";
import { get_user } from "../../lib";
import { toastError } from "helpers/toastHelper";
import { getListShipping } from "../../apis/shipping";
import OrderInformation from "./Components/OrderInformation";
import InvoiceItems from "./Components/InvoiceItems";
import CancelModal from "./Components/cancelModal";
import { toastSuccess } from "../../helpers/toastHelper";
import RefundModal from "./Components/RefundModal";

const Detail = ({type}) => {
  const { invoiceId } = useParams();
  const dispatch = useDispatch();
  const {invoiceStatus} = useInvoice();
  const { data}  = useSelector(state => state.invoices.detailInvoice);
  const current_user_lib = get_user();
  const navigate = useNavigate();
  const [reason, setReson] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [dateTime, setDateTime] = useState(new Date().toISOString().split('T')[0]);
  const [returnModal, setReturnModal] = useState(false)

  useEffect(() => {
    dispatch(fetchDetailInvoice({type: type, invoiceId: invoiceId}));
  }, [invoiceId]);

  const handleSellerUpdateStatusInvoice = async (status) => {
    if(status === "cancelled") {
      Swal.fire({
        title: 'Bạn có chắc chắn?',
        text: "Hủy đơn hàng",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        cancelButtonText: "Đóng"}
        ).then((result) => {
        if (result.isConfirmed) {
          setOpenModal(false)
          toastSuccess("Huỷ đơn thành công")
          dispatch(updateStatusInvoice({invoiceUserId: invoiceId, status: status, type: type, remark: reason}))
          setTimeout(() => {
            dispatch(fetchDetailInvoice({type: type, invoiceId: invoiceId}));
          }, 1500);
        }
      });
    }else{
      const array = [];
      const { data } = await getListShipping(current_user_lib?.shop?.id);
      data.data.forEach((element) => {
        if (element?.available_status === "active") {
          array.push(element?.available_status);
        }
      });
      if(current_user_lib.address.trim() === ""){
        toastError("Bạn cần cập nhật địa chỉ trước khi chấp nhận đơn");
        return navigate('/nguoi-dung/trang-ca-nhan');
      }
      if (array.length > 0) {
        navigate(`/nguoi-dung/thong-tin-don-hang-ban/${invoiceId}/van-don`);
      } else {
        toastError("Bạn cần thêm đơn vị vận chuyển trước khi chấp nhận đơn");
        navigate('/nguoi-dung/thiet-lap-don-vi-van-chuyen')
      }
    }
  }

  const handleSellerUpdateStatusInvoiceOld = async (status) => {
    dispatch(updateStatusInvoiceOld({invoiceUserId: invoiceId, status: status, type: type}))
    toastSuccess("Đã chấp nhận đơn hàng");
    setTimeout(() => {
      dispatch(fetchDetailInvoice({type: type, invoiceId: invoiceId}));
    }, 1500);
  }

  const handleBuyerUpdateStatusInvoice = () => {
    Swal.fire({
      title: 'Bạn có chắc chắn?',
      text: "Hủy đơn hàng",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      cancelButtonText: "Đóng"
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenModal(false)
        toastSuccess("Huỷ đơn thành công")
        dispatch(updateStatusInvoice({invoiceUserId: invoiceId, status: "cancelled", type: type, remark: reason}))
        setTimeout(() => {
          dispatch(fetchDetailInvoice({type: type, invoiceId: invoiceId}));
        }, 1500);
      }
    });
  }
  const handleCancelInvoice = () => {
    setOpenModal(true)
  }
  const handleBuyerReturnInvoice = () => {
    setReturnModal(true)
  }
  const calculateDays = (updatedAt) => {
    const createdDate = new Date(updatedAt);

    const currentDate = new Date();

    const timeDifference = currentDate - createdDate;
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return dayDifference;
  };
  return (
    <>
      <AddMetaTags title={`Thông tin đơn hàng - ${invoiceId}`} />
      <div className="detail-invoice">
        <div className="row">
          <LeftSidebar />
          <div className="col-md-9 col-sm-12">
            <OrderInformation data={data} invoiceStatus={invoiceStatus} showReturnInfo={false} />
            <InvoiceItems  sellerName={data.seller_name} invoiceItems={data.invoice_items} totalPrice={data.price_total} />
            <DetectAction type={type} status={data.status}>
              {type === "seller" && data.status === "pending" ?
                (<div className="mt-2 d-flex">
                  <button className="btn btn-danger" onClick={() => handleCancelInvoice()}>Hủy đơn</button>
                  <button className="btn btn-info ml-2 text-white" onClick={() => handleSellerUpdateStatusInvoiceOld("delivered")}>Chấp nhận</button>
                  {/* <button className="btn btn-success ml-2" onClick={() => handleSellerUpdateStatusInvoice('ready_to_ship')}>Xử lý đơn</button> */}
                </div>) : (
                <div className="mt-2 d-flex">
                  <button className="btn btn-danger" onClick={() => handleCancelInvoice()}>Hủy đơn</button>
                </div>)
              }
            </DetectAction>
            {
              type === "buyer" && data.status === "delivered" && calculateDays(data?.delivered_at) <= 3 ?
                <div className="mt-2 d-flex">
                  <button className="btn btn-warning" onClick={() => handleBuyerReturnInvoice()}>Trả hàng</button>
                </div>
              :
              ''
            }
          </div>
        </div>
      </div>
      <CancelModal
        type={type}
        openModal={openModal}
        handleSellerUpdateStatusInvoice={handleSellerUpdateStatusInvoice}
        handleBuyerUpdateStatusInvoice={handleBuyerUpdateStatusInvoice}
        setOpenModal={setOpenModal}
        setReson={setReson}
      />
      <RefundModal
        invoiceUserId = {data.id}
        type={type}
        openModal={returnModal}
        setOpenModal={setReturnModal}
      />
    </>
  );
};

export default Detail;
