import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toastError } from 'helpers/toastHelper';

const CancelModal = ({ 
  setReson, 
  handleSellerUpdateStatusInvoice,
  handleBuyerUpdateStatusInvoice, 
  openModal, 
  setOpenModal, 
  type }) => {

    const closeModal = () => setOpenModal(false);
    
    const handleCancel = () => {
      if (type === "seller") {
        handleSellerUpdateStatusInvoice('cancelled')
      } else {
        handleBuyerUpdateStatusInvoice()
      }
    }

  return (
      <Modal
        show={openModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Lý do huỷ đơn</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <textarea
              className="form-control"
              placeholder="Lý do"
              name="reason"
              onChange={(e) => setReson(e.target.value)}
            />
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-info text-white" onClick={closeModal}>
            Đóng
          </button>
          <button type="button" className="btn btn-primary" onClick={handleCancel}>
            Huỷ đơn
          </button>
        </Modal.Footer>
      </Modal>
  );
};

export default CancelModal;